/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
//@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './theme/fonts';
@import './theme/variables';

.INCOME{
    color: var(--ion-color-INCOME);
}

.backgroundINCOME{
    background: var(--ion-color-INCOME);
}

.EXPENSE{
    color: var(--ion-color-EXPENSE) !important;
}

.backgroundEXPENSE{
    background: var(--ion-color-EXPENSE);
}

.SAVING{
    color: var(--ion-color-INCOME);
}

.backgroundSAVING{
    background: var(--ion-color-INCOME);
}

.negativeSAVING {
    color:var(--ion-color-EXPENSE);
}

.item-interactive.item-has-focus{
    --highlight-background:  var(--ion-color-button-red) !important;
}

.item-has-focus .label-floating.sc-ion-label-md-h{
    color: var(--ion-color-button-red) !important;
}

ion-toggle{
    --handle-background-checked: var(--ion-color-button-red) !important;
    --background-checked: #db2738d1 !important;
}

ion-app{
    .scroll-content{
        overflow-y: auto;
    }
}

ion-toolbar{
    --opacity:1 !important;
}

.header-background{
    backdrop-filter: none;
}

.menu-pane-visible{
    max-width: 200px;
    // border-right: 0;
}

.divCenter{
    height: 100%;
    display: flex;
    align-items: center;
}

.divCenter1{
    height: calc(100% - 40px - 60px);
}

.marginAuto{
    margin-left: auto;
    margin-right: auto;
}

.modalFastLink{ 
    width: 600px;
    height: 500px;
}

.onlyMovil{
    display: none;
}

.displayFlexCenter{
    display: flex;
    align-items: center;
    vertical-align: middle;
}

ion-radio{
    --color-checked: var(--ion-color-button-red);
}

.div_captcha div{
    margin: auto;
}

.contentMovil, .contentMovilClaro, .contentOnlyMovil{
    --offset-top: 43px !important;
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: center center;
}

input[type='text'],
input[type='number'],
input {
  font-size: 16px;
}

/*.contentMovil, .contentOnlyMovil{
    --background: url('./assets/img/fondo-imagen-movil.jpg') no-repeat center center / cover;
}

.contentMovilClaro{
    --background: url('./assets/img/fondo-claro.jpg') no-repeat center center / cover;
}
@media (min-width:992px) {
    .contentMovil, .contentMovilClaro, .contentOnlyMovil{
        --offset-top: 0px !important;
    }
    .contentMovil{
        --background: url('./assets/img/fondo-imagen.png') no-repeat center center / cover;
    }
    
    .contentMovilClaro{
        --background: url('./assets/img/fondo-imagen-claro.jpg') no-repeat center center / cover;
    }

    .contentOnlyMovil{
        --background: none;
    }
}*/

.buttonNext{
    width: 100%;
    height: 45px;
    background: var(--ion-color-button-red);
    border-radius: 15px;
    color: white;
    font-family: 'Poppins Medium';
    outline-width: 0 !important;
    font-size: 16px;
    text-transform: uppercase;
}

.buttonClaro{
    width: 100%;
    --background: var(--ion-color-input-claro-background);
    border-radius: 15px;
    height: 45px;
    color: var(--ion-color-letter-input);
    --padding-start: 25px;
    --padding-end: 25px;
    text-align: center;
    font-family: 'Poppins Regular';
    font-size: 16px;
    text-transform: uppercase;
}
ion-input.custom{
    color: white;
    --background: var(--ion-color-input-oscuro-background);
    text-align: center;
}

ion-input.inputOscuro{
    --background: var(--ion-color-input-oscuro-background);
    //border-radius: 15px;
    height: 45px;
    color: white;
    --padding-start: 25px;
    --padding-end: 25px;
    text-align: center;
    font-family: 'Poppins Regular';
    font-size: 16px;
}

.inputClaro{
    --background: var(--ion-color-input-claro-background);
    border-radius: 15px;
    height: 45px;
    color: var(--ion-color-letter-input);
    --padding-start: 25px;
    --padding-end: 25px;
    text-align: center;
    font-family: 'Poppins Regular';
    font-size: 16px;
}

.marginBottom30{
    margin-bottom: 30px;
}

.popoverLang{
    --width: 55px;
}

button{
    outline-width: 0 !important;
}

.divEchart > div{
    margin-left: auto !important;
    margin-right: auto !important;
}

.popoverNotifications{
    --width: 250px;
    --max-height: 425px;
    --height: 90%;
}

div.rawcontent{
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 90%;
    max-width: 1000px;
    font-family: 'Poppins Regular';
    font-size: 15px;
    color: var(--ion-color-letter-input);
    text-align: justify;
    margin-bottom: 100px;
    
    strong{
        font-family: 'Poppins Medium';
    }

    .center{
        text-align: center;
        display: block;
    }

    ul {
        text-align: left;
    }

    ul li {
        margin-left: -20px;
        padding-bottom: 10px;
    }
    .lijustify{
        text-align: justify;
    }

    ul li::marker {
        content: '-'; /* Insert content that looks like bullets */
    }
}

@media (max-width:991px) {
    .popoverNotifications{
        --width: 95%;
    }

    .popoverNotifications .popover-content{
        left: 5% !important;
    }
   
    .onlyMovil{
        display: block;
    }

    .onlyDesktop{
        display: none !important;
    }

    .budgetFormComponentModal{
        .modal-wrapper{
            height: 75%;
            margin-top: auto;
            max-height: 400px;
        }
    } 

    .alertFormComponentModal{
        .modal-wrapper{
            height: 75%;
            margin-top: auto;
            max-height: 460px;
        }
    } 

    .transactionMoveComponentComponentModal{
        .modal-wrapper{
            height: 76%;
            margin-top: auto;
            max-height: 500px;
        }
    }
}

/*** FastLink **/
#container-fastlink{
    height: 100%;
}

#container-fastlink > div{
    overflow: hidden;
    height: 100%;
}

#container-fastlink  iframe{
    max-height: 100% !important;
    overflow: hidden !important; 
}
/*** FastLink **/

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0; 
}

input[type=number] { 
    -moz-appearance:textfield; 
}

mat-option{
    font-family: "Poppins Medium" !important;
    font-size: 13px !important;
    color: var(--ion-color-letter-input) !important;
}

mat-optgroup label{
    font-family: "Poppins Regular" !important;
    font-size: 13px !important;
}

.mat-select-value-text{
    color: var(--ion-color-letter-input) !important;
}

.mat-calendar-body-selected {
    background-color: var(--ion-color-button-red) !important;
}

input[type=email]:-webkit-autofill,
input[type=email]:-webkit-autofill:hover,
input[type=email]:-webkit-autofill:active,
input[type=email]:-webkit-autofill:focus {
  -webkit-animation: autofill 0s forwards;
  -webkit-transition-delay: 5000000ms;
    animation: autofill 0s forwards;
}