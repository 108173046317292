@font-face {
    font-family: 'Poppins Regular';
    src: url('../assets/fonts/PoppinsRegular400.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins Medium';
    src: url('../assets/fonts/PoppinsMedium500.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }